import { HostListener, Component, OnInit } from '@angular/core';
import { BackendService } from '../../services/backend.service';
import { Router } from '@angular/router';
import { BuildingData } from '../../interfaces/building-data';
@Component({
  selector: 'app-kalenteri-lista',
  templateUrl: './kalenteri-lista.component.html',
  styleUrls: ['./kalenteri-lista.component.scss']
})
export class KalenteriListaComponent implements OnInit {

  public kortit: Array<BuildingData> = [];
  public screenSizeWidth: string = '';
  public screenSizeHeight: string = '';
  public screenOrientation: string = 'landscape'
  constructor(
    private backendService: BackendService,
    private _router: Router
  ) {
    this.setResponsiveClasses();
    this.backendService.buildingsListDataObserver.subscribe((response) => {
      if(response) {
        this.kortit = response;
      }
    })
  }

  ngOnInit(): void {
  }

  navigateToCalendar(event, kortti) {
    event.preventDefault();
    event.stopPropagation();
    if(kortti.rooms.length > 8) {
      kortti.showLinks = !kortti.showLinks;
    } else {
      this._router.navigateByUrl('/kalenteri/'+kortti.id)
    }
  }

  @HostListener('window:resize', ['onResize($event)'])
  onResize(e) {
    this.setResponsiveClasses();
  }
  setResponsiveClasses() {
    if(window.innerWidth >= 1921) {
      this.screenSizeWidth = 'width-size-xxl';
    }
    if(window.innerWidth <= 1920 && window.innerWidth >= 1600) {
      this.screenSizeWidth = 'width-size-xl';
    }
    if(window.innerWidth <= 1599 && window.innerWidth >= 1200) {
      this.screenSizeWidth = 'width-size-l';
    }
    if(window.innerWidth <= 1199 && window.innerWidth >= 992) {
      this.screenSizeWidth = 'width-size-m';
    }
    if(window.innerWidth <= 991 && window.innerWidth >= 768) {
      this.screenSizeWidth = 'width-size-s';
    }
    if(window.innerWidth <= 767 && window.innerWidth >= 600) {
      this.screenSizeWidth = 'width-size-xs';
    }
    if(window.innerWidth < 600) {
      this.screenSizeWidth = 'width-size-xxs';
    }

    if(window.innerHeight >= 1921) {
      this.screenSizeHeight = 'height-size-xxl';
    }
    if(window.innerHeight <= 1920 && window.innerHeight >= 1600) {
      this.screenSizeHeight = 'height-size-xl';
    }
    if(window.innerHeight <= 1599 && window.innerHeight >= 1200) {
      this.screenSizeHeight = 'height-size-l';
    }
    if(window.innerHeight <= 1199 && window.innerHeight >= 992) {
      this.screenSizeHeight = 'height-size-m';
    }
    if(window.innerHeight <= 991 && window.innerHeight >= 768) {
      this.screenSizeHeight = 'height-size-s';
    }
    if(window.innerHeight <= 767 && window.innerHeight >= 600) {
      this.screenSizeHeight = 'height-size-xs';
    }
    if(window.innerHeight < 600) {
      this.screenSizeHeight = 'height-size-xxs';
    }

    if(window.innerHeight > window.innerWidth) {
      this.screenOrientation = 'portrate';
    } else {
      this.screenOrientation = 'landscape';
    }
  }
}
