<div class="leiska2"></div>
<section (click)="fullscreen($event)" class="{{screenSizeWidth}} {{screenSizeHeight}} {{screenOrientation}}">
    <header>
        <img src="/assets/logo.png" />
        <app-kello></app-kello>
        <app-progress *ngIf="showProgress" [building]="buildingId" [pages]="pages"></app-progress>
        <h1 *ngIf="pages > 1"><span>{{buildingName}}</span> <strong>{{page}}</strong>/{{pages}}</h1>
        <h1 *ngIf="pages === 1"><span>{{buildingName}}</span></h1>
    </header>
    <span class="page-1" [ngClass]="{'hide': page != 1}" *ngFor="let kortti of kortit; let i = index">
        <app-kortti *ngIf="i<fitsToAPage" [kortti]="kortti"></app-kortti>
    </span>
    <span class="page-2" [ngClass]="{'hide': page != 2}" *ngFor="let kortti of kortit; let i = index">
        <app-kortti *ngIf="i>=fitsToAPage && i<(fitsToAPage*2)" [kortti]="kortti"></app-kortti>
    </span>
    <span class="page-3" [ngClass]="{'hide': page != 3}" *ngFor="let kortti of kortit; let i = index">
        <app-kortti *ngIf="i>=(fitsToAPage*2) && i<(fitsToAPage*3)" [kortti]="kortti"></app-kortti>
    </span>
    <span class="page-4" [ngClass]="{'hide': page != 4}" *ngFor="let kortti of kortit; let i = index">
        <app-kortti *ngIf="i>=(fitsToAPage*3) && i<(fitsToAPage*4)" [kortti]="kortti"></app-kortti>
    </span>
    <span class="page-5" [ngClass]="{'hide': page != 5}" *ngFor="let kortti of kortit; let i = index">
        <app-kortti *ngIf="i>=(fitsToAPage*4) && i<(fitsToAPage*5)" [kortti]="kortti"></app-kortti>
    </span>
    <span class="page-6" [ngClass]="{'hide': page != 6}" *ngFor="let kortti of kortit; let i = index">
        <app-kortti *ngIf="i>=(fitsToAPage*5) && i<(fitsToAPage*6)" [kortti]="kortti"></app-kortti>
    </span>
    <span class="page-7" [ngClass]="{'hide': page != 7}" *ngFor="let kortti of kortit; let i = index">
        <app-kortti *ngIf="i>=(fitsToAPage*6) && i<(fitsToAPage*7)" [kortti]="kortti"></app-kortti>
    </span>
    <span class="page-8" [ngClass]="{'hide': page != 8}" *ngFor="let kortti of kortit; let i = index">
        <app-kortti *ngIf="i>=(fitsToAPage*7) && i<(fitsToAPage*8)" [kortti]="kortti"></app-kortti>
    </span>
    <span class="page-9" [ngClass]="{'hide': page != 9}" *ngFor="let kortti of kortit; let i = index">
        <app-kortti *ngIf="i>=(fitsToAPage*8) && i<(fitsToAPage*9)" [kortti]="kortti"></app-kortti>
    </span>
    <span class="page-10" [ngClass]="{'hide': page != 10}" *ngFor="let kortti of kortit; let i = index">
        <app-kortti *ngIf="i>=(fitsToAPage*9) && i<(fitsToAPage*10)" [kortti]="kortti"></app-kortti>
    </span>
    <span class="page-11" [ngClass]="{'hide': page != 11}" *ngFor="let kortti of kortit; let i = index">
        <app-kortti *ngIf="i>=(fitsToAPage*10) && i<(fitsToAPage*11)" [kortti]="kortti"></app-kortti>
    </span>
</section>