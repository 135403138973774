<section [ngClass]="{'free': kortti.current.free, 'zero': varaustilanne === 0, 'one': varaustilanne === 1, 'two': varaustilanne >= 2}">
    <header>
        <h1>{{kortti.huone}}</h1>
    </header>
    <div *ngIf="!kortti.current.free">
        <h3>{{kortti.current.alku.getHours() + ':'}}{{(kortti.current.alku.getMinutes() <= 9) ? '0' + kortti.current.alku.getMinutes(): kortti.current.alku.getMinutes()}} - {{kortti.current.loppu.getHours() + ':'}}{{(kortti.current.loppu.getMinutes() <= 9) ? '0' + kortti.current.loppu.getMinutes() : kortti.current.loppu.getMinutes()}}</h3>
        <h2>{{kortti.current.otsikko}}</h2>
        <!--div>
            <span>Sisäinen</span>
            Merja yli-ihminen
        </div-->
    </div>
    <div *ngIf="kortti.current.free">
        <h1>Vapaa</h1>
    </div>
    <ul [ngClass]="{'show': !upcoming}">
        <div *ngFor="let varaus of kortti.varaukset; let i = index">
            <span *ngIf="i<2">
                <li *ngIf="varaus.today">
                    <span class="time">{{varaus.alku.getHours() + ':'}}{{(varaus.alku.getMinutes() <= 9) ? '0' + varaus.alku.getMinutes(): varaus.alku.getMinutes()}} - {{varaus.loppu.getHours() + ':'}}{{(varaus.loppu.getMinutes() <= 9) ? '0' + varaus.loppu.getMinutes(): varaus.loppu.getMinutes()}}</span>
                    <span class="title">{{varaus.otsikko}}</span>
                </li>
            </span>
        </div>
    </ul>
</section>