<section class="{{screenSizeWidth}} {{screenSizeHeight}} {{screenOrientation}}">
    <header>
        <img src="/assets/logo.png" />
        <app-kello [fixedStyles]="1"></app-kello>
        <h1>Äänekosken Varauskalenterit</h1>
    </header>
    <div *ngFor="let kortti of kortit" (click)="navigateToCalendar($event, kortti)">
        <article class="kortti">
            <header>
                <h1>{{kortti.building}}</h1>
                <h2>{{kortti.address}}</h2>
                <img *ngIf="kortti.rooms.length > 8" [ngClass]="{'active': kortti.showLinks}" src="/assets/menu.png" width="20" height="20" alt="menu" />
            </header>
            <footer *ngIf="kortti.showLinks">
                <a href="#" routerLink="/kalenteri/{{kortti.id}}/10" routerLinkActive="active">10sec</a>
                <a href="#" routerLink="/kalenteri/{{kortti.id}}/15" routerLinkActive="active">15sec</a>
                <a href="#" routerLink="/kalenteri/{{kortti.id}}/30" routerLinkActive="active">30sec</a>
                <a href="#" routerLink="/kalenteri/{{kortti.id}}/60" routerLinkActive="active">60sec</a>
            </footer>
        </article>
    </div>
</section>