import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EtusivuComponent } from './components/etusivu/etusivu.component';
import { KalenteriListaComponent } from './components/kalenteri-lista/kalenteri-lista.component'
const routes: Routes = [{
  path: '', component: KalenteriListaComponent
},{
  path: 'kalenteri/:building', component: EtusivuComponent
},{
  path: 'kalenteri/:building/:pageupdateTime', component: EtusivuComponent
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
