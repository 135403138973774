import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {
   BuildingData
} from '../interfaces/building-data'
import { Room } from '../interfaces/room';
import { Reservations } from '../interfaces/reservations';
import { PageData } from '../interfaces/page-data';
import { BookingStatus } from '../interfaces/booking-status';
import { GetRooms } from '../interfaces/get-rooms';
import { CompileShallowModuleMetadata } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  public apiKey: string = 'NVAHIERkfre8423dijfAWEF9384SADFOAKJSD23idjfgdsfd';
  public building: string = '';
  public listMode: string = '1';
  public backend: string = 'https://www.aanekoski.fi/codesigner.proxyASIO.action';
  public url: string = this.backend + '?api_key='+this.apiKey;

  public data: BehaviorSubject<Array<BookingStatus>> = new BehaviorSubject(null);
  public dataObserver: Observable<Array<BookingStatus>> = this.data.asObservable();
  
  public buildingData: BehaviorSubject<Array<BuildingData>> = new BehaviorSubject(null);
  public buildingDataObserver: Observable<Array<BuildingData>> = this.buildingData.asObservable();
  
  public pageData: BehaviorSubject<PageData> = new BehaviorSubject(null);
  public pageDataObserver: Observable<PageData> = this.pageData.asObservable();
  
  public buildingNameData: BehaviorSubject<string> = new BehaviorSubject(null);
  public buildingNameDataObserver: Observable<string> = this.buildingNameData.asObservable();
  
  public buildingsListData: BehaviorSubject<Array<BuildingData>> = new BehaviorSubject(null);
  public buildingsListDataObserver: Observable<Array<BuildingData>> = this.buildingsListData.asObservable();
  
  public buildingsRoomOrderData: BehaviorSubject<Array<string>> = new BehaviorSubject(null);
  public buildingsRoomOrderObserver: Observable<Array<string>> = this.buildingsRoomOrderData.asObservable();

  public intervalBuildings;
  public intervalForBookings;

  constructor(private http: HttpClient) {
    this.buildingNameDataObserver.subscribe((response) => {
      if(response) {
        this.building = response;
        this.getBookings();
        this.getBuilding();
        this.getRoomOrderForBuilding()
        if(!this.intervalBuildings) {
          this.intervalBuildings = setInterval(() => {
            this.getBuilding();
          }, 60000 * 60 * 24);
        }
        if(!this.intervalForBookings) {
          this.intervalForBookings = setInterval(() => {
            this.getBookings();
          }, 30000)
        }
      }
    })

    this.getBuildingsForList();
  }

  getBookings() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    this.http.get(this.url + '&op=booking_status&building='+this.building+'&list_mode='+this.listMode + '&lang=fin', httpOptions).subscribe((response: Array<BookingStatus>) => {
      this.data.next(response)
    });
  }

  getBuildingsForList() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    this.http.get(this.url + '&op=get_rooms', httpOptions).subscribe((response: Array<GetRooms>) => {
      let data: Array<BuildingData> = [];
      response.forEach((elem, ind) => {
          let found = false;
          data.forEach((elem2, ind2) => {
            if(elem2.id === elem.building_code) {
              found = true;
              let room: Room = {
                room_code: elem.room_code,
                room_name: elem.room_name,
                room_id: elem.id,
                huone: elem.room_name,
                capacity: elem.capacity,
                capacity_label: elem.capacity_label,
                current: {
                  alku: new Date(),
                  loppu: new Date(),
                  otsikko: 'Vapaa',
                  tyyppi: '',
                  varaaja: '',
                  show: false,
                  free: true,
                  current: true,
                  today: true
                },
                varaukset: []
              }
              elem2.rooms.push(room)
            }
          });
          if(!found) {
            let room: Room = {
              room_code: elem.room_code,
              room_name: elem.room_name,
              room_id: elem.id,
              huone: elem.room_name,
              capacity: elem.capacity,
              capacity_label: elem.capacity_label,
              current: {
                alku: new Date(),
                loppu: new Date(),
                otsikko: 'Vapaa',
                tyyppi: '',
                varaaja: '',
                show: false,
                free: true,
                current: true,
                today: true
              },
              varaukset: []
            };
            let building: BuildingData = {
              id: elem.building_code,
              building: (elem.building_name !== '') ? elem.building_name.trim() : elem.building_code,
              address: elem.address,
              showLinks: false,
              rooms: [room]
            }
            data.push(building);
          }
      })
      this.buildingsListData.next(data)
    });
  }

  getBuilding() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    this.http.get(this.url + '&op=get_rooms&building_code=' + this.building, httpOptions).subscribe((response: Array<GetRooms>) => {
      //this.data.next(response)
      let data = [];
      response.forEach((elem, ind) => {
          let found = false;
          data.forEach((elem2, ind2) => {
            if(elem2.id === elem.building_code) {
              found = true;
              let room: Room = {
                room_code: elem.room_code,
                room_name: elem.room_name,
                room_id: elem.id,
                huone: elem.room_name,
                capacity: elem.capacity,
                capacity_label: elem.capacity_label,
                current: {
                  alku: new Date(),
                  loppu: new Date(),
                  otsikko: 'Vapaa',
                  tyyppi: '',
                  varaaja: '',
                  show: false,
                  free: true,
                  current: true,
                  today: true
                },
                varaukset: []
              }
              elem2.rooms.push(room)
            }
          });
          if(!found) {
            let room: Room = {
              room_code: elem.room_code,
              room_name: elem.room_name,
              room_id: elem.id,
              huone: elem.room_name,
              capacity: elem.capacity,
              capacity_label: elem.capacity_label,
              current: {
                alku: new Date(),
                loppu: new Date(),
                otsikko: 'Vapaa',
                tyyppi: '',
                varaaja: '',
                show: false,
                free: true,
                current: true,
                today: true
              },
              varaukset: []
            }
            let building: BuildingData = {
              id: elem.building_code,
              building: (elem.building_name !== '') ? elem.building_name.trim() : elem.building_code,
              address: elem.address,
              showLinks: false,
              rooms: [room]
            }
            data.push(building);
          }
      })
      this.dataObserver.subscribe((response2) => {
        if(response2) {
          data.forEach((elem, ind) => {
            elem.rooms.forEach((elem2, ind2) => {
              elem2.varaukset = [];
              response2.forEach((elem3, ind3) => {
                if(elem2.room_code === elem3.room_code) {
                  let varaus: Reservations = {
                    alku: new Date(elem3.start),
                    loppu: new Date(elem3.end),
                    otsikko: elem3.title,
                    tyyppi: '',
                    varaaja: '',
                    show: false,
                    free: false,
                    current: false,
                    today: true
                  }
                  elem2.varaukset.push(varaus);
                }
              })
            })
          });
          data.forEach((elem, ind) => {
            elem.rooms.forEach((elem2) => {
              if(elem2.huone.indexOf(elem.building) === 0) {
                if((elem2.huone.length - elem.building.length) > 5) {
                  elem2.huone = elem2.huone.substring(elem.building.length).trim()
                  if(elem2.huone.indexOf(', ') === 0) {
                    elem2.huone = elem2.huone.substring(2)
                  }
                }
              }
            })
          })
          this.buildingData.next(data)
        }
      });
    });
  }
  getRoomOrderForBuilding() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    this.http.get(this.url + '&op=get_rooms_order&building=' + this.building, httpOptions).subscribe((response: Array<string>) => {
      this.buildingsRoomOrderData.next(response);
    });
  }
}
