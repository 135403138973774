import { Component, OnInit, Input } from '@angular/core';
import { Room } from '../../interfaces/room';
import { Reservations } from '../../interfaces/reservations';
@Component({
  selector: 'app-kortti',
  templateUrl: './kortti.component.html',
  styleUrls: ['./kortti.component.scss']
})
export class KorttiComponent implements OnInit {
  @Input() kortti: Room;
  public upcoming = true
  public varaustilanne = 0;
  constructor() {
  }

  ngOnInit(): void {
    let found = false;
    this.kortti.varaukset.forEach((elem: Reservations, ind) => {
      if(elem.today) {
        this.varaustilanne++
      }
      if(elem.show) {
        found = true;
      }
    })
    if(!found) {
      this.upcoming = false
    }
  }

}
