import { Component, OnInit, Input } from '@angular/core';
import { BackendService } from '../../services/backend.service' 
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnInit {
  @Input() building: string = '';
  @Input() pages: number = 1;
  public now: number = 0;
  public interval;
  public pageUpdateSpeed = 15;
  public height: string = '0%';
  constructor(
    private backendService: BackendService, 
    private route: ActivatedRoute
  ) {

    this.route.params.subscribe(params => {
      if(params['pageupdateTime']){
        this.pageUpdateSpeed = parseInt(params['pageupdateTime'], 10)
      }  
    }) 
    this.backendService.pageDataObserver.subscribe((response) => {
      if(response) {
        if(response.building === this.building) {
          this.now = 0;
        }
      }
    })
  }

  ngOnInit(): void {
    if(this.pages>1) {
      this.timeoutFn();
    } else {
      this.now = 0
    }
  }
  timeoutFn() {
    setTimeout(() => {
      if(this.now >= this.pageUpdateSpeed) {
        this.now = 0;
      }
      this.now += 0.1
      this.height = ((this.now/this.pageUpdateSpeed)*100) + '%';
      this.timeoutFn();
    }, 100);
  }

}
