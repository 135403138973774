import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-kello',
  templateUrl: './kello.component.html',
  styleUrls: ['./kello.component.scss']
})
export class KelloComponent implements OnInit {
  @Input() fixedStyles: number = 0;
  public time: string = '';
  public date: string = '';
  public interval;
  constructor() {
    if(!this.interval) {
      this.interval = setInterval(() => {
        this.updateClock();
      }, 1000);
    }
  }

  ngOnInit(): void {
  }
  updateClock() {
    let newDate = new Date();
    let minutes = (newDate.getMinutes() <= 9) ? '0'+ newDate.getMinutes() : newDate.getMinutes();
    this.time = newDate.getHours() + ':' + minutes;
    let weekdays = ['Su','Ma','Ti','Ke','To','Pe','La'];
    this.date = weekdays[newDate.getDay()] + ' ' + newDate.getDate() + '.' + (newDate.getMonth() + 1)
  }
}
