import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EtusivuComponent } from './components/etusivu/etusivu.component';
import { KorttiComponent } from './components/kortti/kortti.component';
import { KelloComponent } from './components/kello/kello.component';
import { ProgressComponent } from './components/progress/progress.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { KalenteriListaComponent } from './components/kalenteri-lista/kalenteri-lista.component';

@NgModule({
  declarations: [
    AppComponent,
    EtusivuComponent,
    KorttiComponent,
    KelloComponent,
    ProgressComponent,
    KalenteriListaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
