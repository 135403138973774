import { HostListener, Component, OnInit } from '@angular/core';
import { BackendService } from '../../services/backend.service';
import { ActivatedRoute } from '@angular/router';
import { Room } from 'src/app/interfaces/room';

@Component({
  selector: 'app-etusivu',
  templateUrl: './etusivu.component.html',
  styleUrls: ['./etusivu.component.scss']
})
export class EtusivuComponent implements OnInit {

  public kortit: Array<any> = [];
  public page: number = 1;
  public pages: number = 1;
  public showProgress: boolean = false;
  public buildingName: string = '';
  public buildingId: string = '';
  public interval;
  public pageUpdateSpeed: number = 15;
  public screenSizeWidth: string = '';
  public screenSizeHeight: string = '';
  public screenOrientation: string = 'landscape'
  public fitsToAPage: number = 8;
  public cardOrder: Array<string> = []
  constructor(
    private backendService: BackendService, 
    private route: ActivatedRoute
  ) {
    this.setResponsiveClasses();
    this.backendService.buildingsRoomOrderObserver.subscribe((response) => {
      if(response) {
        this.cardOrder = response;
      }
    });
    this.route.params.subscribe(params => {
      if(params['pageupdateTime']){
        this.pageUpdateSpeed = parseInt(params['pageupdateTime'], 10)
      }  
      this.backendService.buildingNameData.next(params['building'])
      this.buildingId = params['building'];
      if(!this.interval) {
        this.interval = setInterval(() => {
          if(this.page < this.pages) {
            this.page++
          } else {
            this.page = 1
          }
          this.backendService.pageData.next({
            building: params['building'],
            page: this.page
          })
        }, this.pageUpdateSpeed * 1000)
      }
    });
    this.backendService.buildingDataObserver.subscribe((response) => {
      if(response) {
        response.forEach((building) => {
          if(building.id === this.backendService.building) {
            this.buildingName = building.building
            this.kortit = building.rooms
            this.pages = Math.ceil(this.kortit.length/this.fitsToAPage);
            this.showProgress = true;
            this.kortit.forEach((elem, ind) => {
              elem.varaukset.sort((a:any, b:any) => {
                return a.alku.getTime() < b.alku.getTime();
              });
              elem.varaukset.forEach((elem2, ind2) => {
                if((elem2.alku.getTime() < new Date().getTime())) {
                  elem2.current = true;
                  elem.current = elem2;
                  elem.varaukset.splice(ind2, 1)
                }
                elem2.show = true;
                if(!(elem2.loppu.getTime() > new Date().getTime())) {
                  elem2.show = true;
                }
              });
              elem.varaukset.forEach((elem, ind) => {
                if(elem.alku.getDate() === new Date().getDate()) {
                  elem.today = true;
                } else {
                  elem.today = false;
                }
              })
              if((elem.current.loppu.getTime() <= new Date().getTime())) {
                elem.current = {
                  alku: new Date(),
                  loppu: new Date(),
                  otsikko: 'Vapaa',
                  tyyppi: '',
                  varaaja: '',
                  show: false,
                  free: true,
                  current: true,
                  today: true
                }

                elem.varaukset.forEach((elem2, ind2) => {
                  if((elem2.alku.getTime() < new Date().getTime())) {
                    elem2.current = true;
                    elem.current = elem2;
                    elem.varaukset.splice(ind2, 1)
                  }
                })
              }
            });
          }
        })
        if(this.cardOrder.length) {
          let newOrder = [];
          this.cardOrder.forEach((elem, ind) => {
            newOrder.push(this.getKortti(elem))
          })
          this.kortit = newOrder;
          this.pages = Math.ceil(this.kortit.length/this.fitsToAPage);
        }
      }
    });
  }

  ngOnInit(): void {
  }
  getKortti(room_code) {
    let returnObj;
    this.kortit.forEach((elem, ind) => {
      if(room_code === elem.room_code) {
        returnObj = elem;
      }
    });
    return returnObj;
  }

  @HostListener('window:resize', ['onResize($event)'])
  onResize(e) {
    this.setResponsiveClasses();
  }
  setResponsiveClasses() {
    let xlHeight = false;
    let sHeight = false;

    let xxsWidth = false;
    let xxsHeight = false;
    let xxlHeight = false;
    let xxlWidth = false;
    let lWidth = false;
    let xsHeight = false;
    let lHeight = false;
    let xsWidth = false;
    if(window.innerWidth >= 1921) {
      this.screenSizeWidth = 'width-size-xxl';
      xxlWidth = true;
    }
    if(window.innerWidth <= 1920 && window.innerWidth >= 1600) {
      this.screenSizeWidth = 'width-size-xl';
    }
    if(window.innerWidth <= 1599 && window.innerWidth >= 1200) {
      this.screenSizeWidth = 'width-size-l';
      lWidth = true;
    }
    if(window.innerWidth <= 1199 && window.innerWidth >= 992) {
      this.screenSizeWidth = 'width-size-m';
    }
    if(window.innerWidth <= 991 && window.innerWidth >= 768) {
      this.screenSizeWidth = 'width-size-s';
    }
    if(window.innerWidth <= 767 && window.innerWidth >= 600) {
      this.screenSizeWidth = 'width-size-xs';
      xsWidth = true;
    }
    if(window.innerWidth < 600) {
      this.screenSizeWidth = 'width-size-xxs';
      xxsWidth = true;
    }

    if(window.innerHeight >= 1921) {
      this.screenSizeHeight = 'height-size-xxl';
      xxlHeight = true;
    }
    if(window.innerHeight <= 1920 && window.innerHeight >= 1600) {
      this.screenSizeHeight = 'height-size-xl';
      xlHeight = true;
    }
    if(window.innerHeight <= 1599 && window.innerHeight >= 1200) {
      this.screenSizeHeight = 'height-size-l';
      lHeight = true;
    }
    if(window.innerHeight <= 1199 && window.innerHeight >= 992) {
      this.screenSizeHeight = 'height-size-m';
    }
    if(window.innerHeight <= 991 && window.innerHeight >= 768) {
      this.screenSizeHeight = 'height-size-s';
      sHeight = true;
    }
    if(window.innerHeight <= 767 && window.innerHeight >= 600) {
      this.screenSizeHeight = 'height-size-xs';
      xsHeight = true;
    }
    if(window.innerHeight < 600) {
      this.screenSizeHeight = 'height-size-xxs';
      xxsHeight = true;
    }

    if(window.innerHeight > window.innerWidth) {
      this.screenOrientation = 'portrate';
      if(sHeight || xxsWidth) {
        this.fitsToAPage = 2;
      } else {
        if(xxlWidth && xxlHeight) {
          this.fitsToAPage = 8;
        } else {
          if(lHeight && xsWidth) {
            this.fitsToAPage = 8;
          } else {
            this.fitsToAPage = 4;
          }
        }
      }
    } else {
      this.screenOrientation = 'landscape';
      this.fitsToAPage = 8;
      if(xsHeight || xxsHeight) {

        this.fitsToAPage = 4;
      }
    }
    if(xlHeight) {
      this.fitsToAPage = 8;
    }
    this.pages = Math.ceil(this.kortit.length/this.fitsToAPage);
  }
  fullscreen(e) {
    e.preventDefault();
    e.stopPropagation();
    this.openFullscreen(document.querySelectorAll('body')[0])

  }
  openFullscreen(elem) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }
  }

}
